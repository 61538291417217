import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';

import { ICommissionPlan, ICommissionPlanForm, ICommonSelectItem } from '@core/interfaces';
import { CommissionService, ToastService } from '@core/services';

@Component({
    selector: 'app-add-edit-commission-plan',
    templateUrl: './add-commission-plan.component.html',
    styleUrls: ['./add-commission-plan.component.scss']
})
export class AddCommissionPlanComponent implements OnInit {

    public commissionPlanForm: FormGroup<ICommissionPlanForm> = new FormGroup<ICommissionPlanForm>({
        Period: new FormControl(null, [Validators.required]),
        IsNegative: new FormControl(false, [Validators.required]),
        CalculationTime: new FormControl(null, [Validators.required]),
        AgentId: new FormControl(this.config.data.AgentId, [Validators.required]),
    });
    public pending: boolean;
    public periods: ICommonSelectItem[] = this.config.data.Periods;

    constructor(
        public ref: DynamicDialogRef,
        private router: Router,
        public config: DynamicDialogConfig,
        private toastService: ToastService,
        private translateService: TranslateService,
        private commissionService: CommissionService,
    ) {
        this.subscribeToRouterEvents();
    }

    ngOnInit(): void {

    }

    public saveCommissionPlan(): void {
        if (this.commissionPlanForm.valid) {
            if (this.commissionPlanForm.get('CalculationTime').value) {
                const date = new Date(this.commissionPlanForm.get('CalculationTime').value);
                date.setSeconds(0);
                date.setMilliseconds(0);
                this.commissionPlanForm.get('CalculationTime').setValue(date);
            }
            this.commissionService.addCommissionPlan(this.commissionPlanForm.value as ICommissionPlan)
                .pipe(take(1))
                .subscribe({
                    next: res => {
                        this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'CommissionPlan');
                        this.ref.close(res);
                        this.pending = false;
                    },
                    error: (err: HttpErrorResponse) => {
                        this.pending = false;
                        this.toastService.showToastMsg('error', err?.error?.message);
                    }
                });
        } else {
            this.commissionPlanForm.markAllAsTouched();
        }
    }

    public onCancel(): void {
        this.ref.close();
    }

    private subscribeToRouterEvents(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.ref.close();
            });
    }

}
