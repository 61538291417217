<div id="ag-table-wrapper">
    <ag-grid-angular
        style="width: 100%"
        *ngIf="translationsLoaded"
        [class]="themeService.colorScheme === 'light' ? 'ag-theme-' + theme : 'ag-theme-'+theme+'-dark'"
        [domLayout]='domLayout'
        [masterDetail]="masterDetail"
        [isRowMaster]="isRowMaster"
        [detailCellRendererParams]="detailCellRendererParams"
        [detailCellRenderer]="detailCellRenderer"
        [detailRowAutoHeight]="detailRowAutoHeight"
        [getRowHeight]="getRowHeight"
        [getRowClass]="getRowClass"
        [getContextMenuItems]="getContextMenuItemsFunction"
        [headerHeight]="headerHeight"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [rowData]="rowData"
        [rowHeight]="rowHeight"
        [rowSelection]="rowSelection"
        [pinnedBottomRowData]="pinnedBottomRowData"
        [suppressRowClickSelection]="suppressRowClickSelection"
        [components]="components"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [rowModelType]="rowModelType"
        [serverSideStoreType]="serverSideStoreType"
        [sideBar]="sideBar"
        [animateRows]="true"
        [overlayNoRowsTemplate]="noRowsTemplate"
        [localeText]="localeTranslations"
        [maxBlocksInCache]="maxBlocksInCache"
        [treeData]="treeData"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [groupDefaultExpanded]="groupDefaultExpanded"
        [getDataPath]="getDataPath"
        [isServerSideGroup]="isServerSideGroup"
        [getServerSideGroupKey]="getServerSideGroupKey"
        [getRowId]="getRowId"
        [isRowSelectable]="isRowSelectable"
        [suppressRowVirtualisation]="suppressRowVirtualisation"
        [enableRtl]="configService.isRtl"

        (selectionChanged)="onSelectionChanged($event)"
        (paginationChanged)="onPaginationChanged($event)"
        (cellClicked)="onCellClicked($event)"
        (rowClicked)="onRowClicked($event)"
        (firstDataRendered)="onFirstDataRendered($event)"
        (gridReady)="onGridReady($event)"
        (gridSizeChanged)="onGridSizeChanged($event)"
        (columnEverythingChanged)="saveColumnDefs()"
        (columnMoved)="saveColumnDefs()"
        (columnResized)="saveColumnDefs()"
        (columnPinned)="saveColumnDefs()"
        (cellValueChanged)="onCellValueChanged($event)"
        (rowGroupOpened)="onRowGroupOpened($event)"
        (cellFocused)="onCellFocused($event)"
        (filterModified)="onFilterModified($event)"

    ></ag-grid-angular>
</div>
