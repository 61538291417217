import * as menuItems from '../menu-items/admin-menu';

export const environment = {
    ApiUrl: 'https://api.blue-version.com/api/agentmanagement/',
    AGGridLicence: 'CompanyName=CG Soft LLC,LicensedApplication=CGS,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-026402,ExpiryDate=17_March_2023_[v2]_MTY3OTAxMTIwMDAwMA==dd30e37fd5c9e3f4f9954b9b6d22b38e',
    role: 'admin',
    cdnUrl: 'https://cdn.blue-version.com/',
    production: true,
    menuItems: menuItems.AdminMenu
};
