import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from '@core/components';

import { AppNotfoundComponent } from '@pages/notfound/app.notfound.component';
import { canActivatePermissionsGuard, canActivateUnAuthGuard, canMatchPermissionsGuard, canMatchUserTypeGuard } from '@core/guards';
import { canActivateChildAuthGuard } from '@core/guards/auth.guard';
import { AppMaintenanceComponent } from '@pages/maintenance';
import { AccessDeniedComponent } from '@pages/acces-denied';
import { permissionGetter } from '@core/utilities';

const APP_ROUTES: Routes = [
    {
        path: '', component: AppMainComponent,
        canActivateChild: [canActivateChildAuthGuard],
        children: [
            // {
            //     path: '',
            //     data: {
            //         breadcrumb: {
            //             title: 'Dashboard',
            //             navigate: true,
            //         }
            //
            //     },
            //     component: DashboardDemoComponent
            // },
            {
                path: '',
                redirectTo: 'agents',
                pathMatch: 'full'
            },
            {
                path: 'agents',
                loadChildren: () => import('@pages/agents/agents.module').then(m => m.AgentsModule),
                data: { permissions: permissionGetter('AgentPermissions', 'ViewAgent') },
                canMatch: [canMatchPermissionsGuard],
                canActivate: [canActivatePermissionsGuard],
            },
            {
                path: 'players',
                loadChildren: () => import('./pages/players/players.module').then(m => m.PlayersModule),
                data: { permissions: permissionGetter('PlayerPermissions', 'ViewPlayer') },
                canMatch: [canMatchPermissionsGuard],
            },
            {
                path: 'logs',
                loadChildren: () => import('./pages/logs/logs.module').then(m => m.LogsModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('LogsPermissions', 'ViewLogsPermissions') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'users',
                loadChildren: () => import('@pages/admin-users/admin-users.module').then(m => m.AdminUsersModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('AdminUserPermissions', 'ViewAdminUser') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'roles',
                loadChildren: () => import('@pages/roles/roles.module').then(m => m.RolesModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('RolesPermissions', 'ViewRoles') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'tasks',
                loadChildren: () => import('@pages/tasks/tasks.module').then(m => m.TasksModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('TasksPermissions', 'ViewTasks') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'player-requests',
                loadChildren: () => import('./pages/player-requests/player-requests.module').then(m => m.PlayerRequestsModule),
            },
            {
                path: 'partners',
                loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('PartnerPermissions', 'ViewPartner') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'messaging-center',
                loadChildren: () => import('@pages/messaging-center/messaging-center.module').then(m => m.MessagingCenterModule),
                data: { userTypes: ['agent'] },
                canMatch: [canMatchUserTypeGuard],
            },
            {
                path: 'agent-transactions',
                loadChildren: () => import('@pages/agent-transactions/agent-transactions.module')
                    .then(m => m.AgentTransactionsModule),
            },
            {
                path: 'user-transactions',
                loadChildren: () => import('@pages/user-transactions/user-transactions.module')
                    .then(m => m.UserTransactionsModule),
                data: { userTypes: ['admin'], permissions: permissionGetter('TransactionsPermissions', 'ViewUserTransactions') },
                canMatch: [canMatchUserTypeGuard, canMatchPermissionsGuard],
            },
            {
                path: 'reports',
                loadChildren: () => import('@pages/reports/reports.module').then(m => m.ReportsModule),
            },
            {
                path: 'my-profile',
                loadChildren: () => import('@pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
            },
            {
                path: 'cms',
                loadChildren: () => import('@pages/cms/cms.module').then(m => m.CmsModule),
                data: { userTypes: ['admin'] },
                canMatch: [canMatchUserTypeGuard],
            },
            { path: 'access-denied', component: AccessDeniedComponent },
        ]
    },
    {
        path: 'register-player',
        loadChildren: () => import('@pages/register-player/register-player.module')
            .then(m => m.RegisterPlayerModule)
    },
    { path: 'notfound', component: AppNotfoundComponent },
    { path: 'under-maintenance', component: AppMaintenanceComponent },

    {
        path: 'login',
        canActivate: [canActivateUnAuthGuard],
        loadChildren: () => import('@pages/auth/auth.module').then(m => m.AuthModule)
    },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(APP_ROUTES, {
            scrollPositionRestoration: 'enabled',
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
