import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { ColGroupDef, GridApi } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, LanguageService, PartnersService, PermissionsService, ToastService } from '@core/services';
import { AdminUsersService, PartnerNameRendererComponent } from '@pages/admin-users';
import { IAdminUserPartner } from '@core/interfaces';
import { generateColumnDefs } from '@core/utilities';

@Component({
    selector: 'app-admin-user-partners',
    templateUrl: './admin-user-partners.component.html',
    styleUrls: ['./admin-user-partners.component.scss']
})
export class AdminUserPartnersComponent implements OnInit {

    /** @desc Table name to store column definitions in the local storage. */
    public tableNameUserPartners: string = 'user-partners';
    public tableNameAdminUserPartners: string = 'admin-user-partners';

    /** @desc The list of Columns in gridOptions.columnDefs can be a mix of Columns and Column Groups.
     *  You can mix and match at will, every level can have any number of Columns and Column Groups and in any order.
     */
    public columnDefsUserPartners: ColGroupDef[] = [
        {
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'UserPartners'),
            children: [
                {
                    field: 'Id',
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    field: 'Partner',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Partner'),
                    cellRenderer: PartnerNameRendererComponent,
                    cellRendererParams: {
                        data: {
                            partners: this.partnersService.partners$,
                        }
                    },
                    valueGetter: params => params.data.PartnerName,
                    filter: 'agTextColumnFilter',
                    sortable: true,
                    minWidth: 80,
                },
            ],
        },
    ];
    public columnDefsAdminUserPartners: ColGroupDef[] = [
        {
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'MyPartners'),
            children: [
                {
                    field: 'Id',
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true,
                    showDisabledCheckboxes: true,
                    minWidth: 30,
                    maxWidth: 30,
                },
                {
                    field: 'Partner',
                    headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Partner'),
                    cellRenderer: PartnerNameRendererComponent,
                    cellRendererParams: {
                        data: {
                            partners: this.partnersService.partners$,
                        }
                    },
                    valueGetter: params => params.data.PartnerName,
                    filter: 'agTextColumnFilter',
                    sortable: true,
                    minWidth: 80,
                },
            ],
        }
    ];
    public rowSelection: 'single' | 'multiple' = 'multiple';

    private adminUserId: number;
    private userId: number;
    public userPartner: IAdminUserPartner[];
    public adminUserPartner: IAdminUserPartner[];
    public userPartnerSelectedRow: IAdminUserPartner[] = [];
    public adminUserPartnerSelectedRow: IAdminUserPartner[] = [];
    public isRowSelectable = partnerId => !partnerId.data.Disabled;
    public getRowClass = rowData => rowData.data.Disabled ? 'disabled-row' : '';


    constructor(
        private languageService: LanguageService,
        private translateService: TranslateService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private adminUsersService: AdminUsersService,
        private toastService: ToastService,
        private partnersService: PartnersService,
        public permissionsService: PermissionsService,
    ) {
        this.columnDefsUserPartners[0].children =
            generateColumnDefs(this.tableNameUserPartners, this.columnDefsUserPartners[0].children);
        this.columnDefsAdminUserPartners[0].children =
            generateColumnDefs(this.tableNameAdminUserPartners, this.columnDefsAdminUserPartners[0].children);
    }

    ngOnInit(): void {
        this.adminUserId = this.authService.userData$.getValue().Id;
        this.userId = +this.route.parent.snapshot.params.id;
        this.getAdminUserPartners();
    }

    private getAdminUserPartners(): void {
        forkJoin([
            this.adminUsersService.getPartnersByUserId({ userId: this.adminUserId }),
            this.adminUsersService.getPartnersByUserId({ userId: this.userId })]
        )
            .pipe(take(1))
            .subscribe({
                next: (res: [IAdminUserPartner[], IAdminUserPartner[]]) => {
                    this.adminUserPartner =
                        this.adminUsersService.disableSelectableRows(res[0], res[1], 'PartnerId', true);
                    this.userPartner =
                        this.adminUsersService.disableSelectableRows(res[1], res[0], 'PartnerId', false);
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                },
            });
    }

    public adminUserPartnersChange($event: GridApi): void {
        this.adminUserPartnerSelectedRow = $event.getSelectedRows();
    }

    public userPartnersChange($event: GridApi): void {
        this.userPartnerSelectedRow = $event.getSelectedRows();
    }

    public addPartnerToUser(): void {
        const payload = {
            PartnerIds: this.adminUserPartnerSelectedRow.map(partner => partner.PartnerId),
            UserId: this.userId,
        };
        this.adminUsersService.addPartnerToUser(payload)
            .pipe(take(1))
            .subscribe({
                next: (res: IAdminUserPartner[]) => {
                    this.userPartner =
                        this.adminUsersService.disableSelectableRows(res, this.adminUserPartner, 'PartnerId', false);
                    this.adminUserPartner =
                        this.adminUsersService.disableSelectableRows(this.adminUserPartner, res, 'PartnerId', true);
                    this.adminUserPartnerSelectedRow = [];
                    this.userPartnerSelectedRow = [];
                    this.toastService.showToastMsg('success', 'CreationSuccessMessage', 'Partner');
                },
                error: (err: HttpErrorResponse) => this.toastService.showToastMsg('error', err?.error?.message),
            });

    }

    public removePartnerFromUser(): void {
        const payload = {
            PartnerIds: this.userPartnerSelectedRow.map(partner => partner.PartnerId),
            UserId: this.userId,
        };
        this.adminUsersService.removePartnerFromUser(payload)
            .pipe(take(1))
            .subscribe({
                next: (res: IAdminUserPartner[]) => {
                    this.userPartner =
                        this.adminUsersService.disableSelectableRows(res, this.adminUserPartner, 'PartnerId', false);
                    this.adminUserPartner =
                        this.adminUsersService.disableSelectableRows(this.adminUserPartner, res, 'PartnerId', true);
                    this.adminUserPartnerSelectedRow = [];
                    this.userPartnerSelectedRow = [];
                    this.toastService.showToastMsg('success', 'RemoveSuccessMessage', 'Partner');
                },
                error: (err: HttpErrorResponse) => this.toastService.showToastMsg('error', err?.error?.message),
            });

    }
}
