import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { IEnum } from '@core/interfaces';

@Component({
    selector: 'app-state-cell-component',
    template: `
        <div *ngIf="!data.translationPrefix; else TranslationBlock"
             [ngStyle]="{ 'color': status?.Color, 'background-color': status?.Background }"
             class="status-badge">
            {{ status?.Name | translate }}
        </div>

        <ng-template #TranslationBlock>
            <div [ngStyle]="{ 'color': status?.Color, 'background-color': status?.Background }"
                 class="status-badge">
                {{ status?.TranslatedName | translate }}
            </div>
        </ng-template>
    `
})
export class StateCellRendererComponent implements ICellRendererAngularComp, OnInit, OnDestroy {

    public data: any;
    public statuses: IEnum[];
    public status: IEnum;

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    // gets called once before the renderer is used
    agInit(params: ICellRendererParams & { paramsData: any }): void {
        this.data = params.paramsData ? { ...(params.paramsData) } : params.data;
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    private setEnum(states: IEnum[]): IEnum[] {
        return  states.map((state: IEnum) => {
            if (state.Name) {
                state.TranslatedName = this.data.translationPrefix + state.Name;
            }
            return state;
        });
    }

    getCellParams(params: ICellRendererParams): void {
        this.data.statesSubject
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                this.statuses = res;
                this.setEnum(this.statuses);
                this.status = res.find(status => status.Value === params.value);
            });
    }
}
