<ng-container *ngIf="!adminUserDetail; else adminUserDetailBlock">
    <div class="spinner-container">
        <i class="pi pi-spin pi-spinner ml-2"></i>
    </div>
</ng-container>
<ng-template #adminUserDetailBlock>
    <div class="inner-page-main-section" [ngClass]="{'m-inner-page-main-section' : !isDesktopView}">
        <div class="p-fluid p-formgrid grid form">
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'FirstName' | translate }}</div>
                        <div class="field-value">{{ adminUserDetail.FirstName ? adminUserDetail.FirstName : '-' }}</div>
                    </div>
                </div>
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'LastName' | translate }}</div>
                        <div class="field-value">{{ adminUserDetail.LastName ? adminUserDetail.LastName : '-' }}</div>
                    </div>
                </div>
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'PhoneNumber' | translate }}</div>
                        <div class="field-value flex align-items-center layout-ltr">
                            <i class="pi icon icon-phone icon-size-sm text-primary mx-2"></i>
                            {{ adminUserDetail.PhoneNumber ? adminUserDetail.PhoneNumber : '-' }}</div>
                    </div>
                </div>
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'Role' | translate }}</div>
                        <div class="field-value">
                            {{
                                (adminUserDetail.RoleId | dataFormatter : (rolesService.roles$ | async) : "Value")
                                || "-"
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6 field-section">
                <div>
                    <div class="field">
                        <div class="field-name">{{ 'Status' | translate }}</div>
                        <app-status-view [statusDetail]="adminUserDetail.StatusDetail"
                                         [translationPrefix]="'EnumAdminUserState'">
                        </app-status-view>
                    </div>
                    <div>
                        <div class="field">
                            <div class="field-name">{{ 'RegistrationDate' | translate }}</div>
                            <div class="field-value layout-ltr">
                                {{ adminUserDetail.RegistrationDate ? (adminUserDetail.RegistrationDate
                                | date:'dd.MM.YYYY HH:mm:ss': adminUserDetail.TimeZone.toString()) : '-' }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="field custom-field-section">
                            <div class="field-name">{{ 'TimeZone' | translate }}</div>
                            <div class="field-value">
                                <app-time-zone-container
                                    [onlyTimeZoneView]="true"
                                    [timeZoneOffset]="adminUserDetail.TimeZone">
                                </app-time-zone-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
