import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '@env/environment';
import { AuthService, HttpService, ThemeService } from '@core/services';
import { IPartnerConfigs } from '@core/interfaces';
import { Methods } from '@core/enums';
import { IAppConfig } from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    public config: IAppConfig = {
        theme: 'lara-light-indigo',
        dark: false,
        inputStyle: 'outlined',
        ripple: true
    };

    public menuMode = 'static';
    public sidebarScrollTop$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public sidebarSmallModeIsActiveSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public menuTheme = 'layout-sidebar-darkgray';

    public inputStyle = 'outlined';

    public ripple: boolean = true;

    private configUpdate = new Subject<IAppConfig>();

    public configUpdate$ = this.configUpdate.asObservable();

    public configClick: boolean;

    public configActive: boolean;

    public partnerConfigs: IPartnerConfigs[];

    public reCaptchaKey: string;

    public isRtl: boolean = false;

    constructor(
        private http: HttpService,
        private authService: AuthService,
        private themeService: ThemeService,
    ) {
        this.isRtl = localStorage.getItem('direction') === 'rtl';
        this.setDOMLayoutDirection();
    }

    private setDOMLayoutDirection(): void {
        this.isRtl ? document.body.classList.add('layout-rtl') : document.body.classList.remove('layout-rtl');
    }

    onConfigClick(event): void {
        this.configClick = true;
    }


    updateConfig(config: IAppConfig): void {
        this.config = config;
        this.configUpdate.next(config);
    }

    getConfig(): IAppConfig {
        return this.config;
    }

    get getPartnerConfigsData(): IPartnerConfigs[] | null {
        return JSON.parse(localStorage.getItem('generalConfigs'));
    }

    async getReCaptchaKey(): Promise<string> {
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
        }
        return JSON.parse(localStorage.getItem('generalConfigs'))?.find(config => config.Key === 'General')
            .Value?.find(config => config.Key === 'ReCaptchaKey')?.Value;
    }

    async load(): Promise<void> {
        if (!localStorage.getItem('generalConfigs')) {
            await this.getPartnerConfigs();
            this.updateFavicon();
        } else {
            this.partnerConfigs = JSON.parse(localStorage.getItem('generalConfigs'));
            this.themeService.setRootColorVariables();
            this.themeService.setSelectedTheme();
            this.updateFavicon();
        }
    }

    async getPartnerConfigs(): Promise<void> {

        return this.http.request('get', environment.ApiUrl + Methods.PARTNER_TEMPLATE_CONFIG)
            .toPromise()
            .then((data: IPartnerConfigs[]) => {
                localStorage.setItem('generalConfigs', JSON.stringify(data));
                this.partnerConfigs = data;
                this.themeService.setSelectedTheme();
                this.themeService.setRootColorVariables();
            });
    }

    private updateFavicon(): void {
        if ( this.partnerConfigs?.find(config => config.Key === 'Light').Value?.find(c => c.Key === 'PrimaryShortLogo')?.Value ) {
            let favIcon: HTMLLinkElement = document.querySelector('#favIcon') as HTMLLinkElement;
            favIcon.href = this.partnerConfigs?.find(config => config.Key === 'Light').Value?.find(c => c.Key === 'PrimaryShortLogo')?.Value;
        }

    }

    public changeDirection(isRtl: boolean): void {
        localStorage.setItem('direction', isRtl ? 'rtl' : 'ltr');
        this.setDOMLayoutDirection();
    }

}
