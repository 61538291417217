import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, EMPTY, forkJoin, iif, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { DialogService } from 'primeng/dynamicdialog';
import { ColDef, GetContextMenuItemsParams, MenuItemDef } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';

import {
    AgentsService,
    AuthService,
    CommissionService,
    LanguageService,
    PermissionsService,
    ToastService
} from '@core/services';
import {
    IAgentDetail,
    ICommission,
    ICommissionInfo,
    ICommonSelectItem
} from '@core/interfaces';
import { AddCommissionPlanComponent } from '@pages/agents/containers';
import { environment } from '@env/environment';
import {
    ActionsRendererComponent,
    AddEditCommissionComponent,
    DateCellRenderer
} from '@app/shared';
import { dataFormatterByValue } from '@core/utilities';

@Component({
    selector: 'app-commission',
    templateUrl: './commission.component.html',
    styleUrls: ['./commission.component.scss']
})
export class CommissionComponent implements OnInit, OnDestroy {

    public agent: IAgentDetail;
    public timeZone: string;

    public agentCommissionInfo: ICommissionInfo;

    public isAdmin: boolean = environment.role === 'admin';
    public myProfileCommissionSection: boolean = false;
    public periods: ICommonSelectItem[];
    public types: ICommonSelectItem[];
    public types$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject<ICommonSelectItem[]>([]);
    public productCategories: ICommonSelectItem[];
    public loading: boolean = false;

    /** @desc Table name to store column definitions in the local storage. */
    public tableName: string = 'commissions';
    public commissions: ICommission[] = [];
    public commissionId: number;

    /** @desc The list of Columns in gridOptions.columnDefs can be a mix of Columns and Column Groups.
     *  You can mix and match at will, every level can have any number of Columns and Column Groups and in any order.
     */
    public columnDefs: ColDef[];
    public pagination: boolean = true;
    public paginationPageSize: number = 50;

    public tableNameHistory: string = 'commission-change-history';
    public columnDefsHistory: ColDef[];
    public commissionChangeHistory: ICommission[];

    public viewCommissionPlanPermission: boolean = false;
    public addCommissionPermission: boolean = false;
    private editCommissionPermission: boolean = false;
    public viewCommissionsChangeHistoryPermission: boolean = false;

    private unsubscribe$: Subject<void> = new Subject<void>();


    constructor(
        private dialogService: DialogService,
        private translateService: TranslateService,
        private languageService: LanguageService,
        private toastService: ToastService,
        private agentsService: AgentsService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private commissionService: CommissionService,
        public permissionsService: PermissionsService,
    ) {
    }

    ngOnInit(): void {
        this.declarePermissions();
        this.getPageData();
        this.timeZone = this.authService.timezoneGetter;
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private declarePermissions(): void {
        this.myProfileCommissionSection = this.router.url.includes('my-profile');
        this.viewCommissionPlanPermission =
            this.permissionsService.checkPermission(this.permissionsService.permissions?.CommissionsPermissions?.ViewCommissions);
        this.addCommissionPermission =
            this.permissionsService.checkPermission(this.permissionsService.permissions?.CommissionsPermissions?.AddCommission);
        this.viewCommissionsChangeHistoryPermission =
            this.permissionsService.checkPermission(this.permissionsService.permissions?.CommissionsPermissions?.ViewCommissionsHistory);
        this.editCommissionPermission =
            this.permissionsService.checkPermission(this.permissionsService.permissions?.CommissionsPermissions?.EditCommission);
    }

    private getPageData(): void {
        iif(() => this.myProfileCommissionSection, this.authService.userData$, this.agentsService.agentInfo$)
            .pipe(
                filter(data => !!data),
                takeUntil(this.unsubscribe$),
                switchMap((agentDetail: IAgentDetail) => {
                    this.agent = agentDetail;
                    const commissionTypes$ = this.commissionService.getCommissionTypes();
                    const commissionPeriods$ = this.commissionService.getCommissionPeriods();
                    const commissionProductCategory$ = this.commissionService.getProductCategory(
                        { partnerId: agentDetail.PartnerId, agentId: agentDetail.ParentId ? agentDetail.Id : 0 });
                    this.loading = true;

                    return forkJoin([commissionTypes$, commissionPeriods$, commissionProductCategory$])
                        .pipe(
                            takeUntil(this.unsubscribe$),
                            switchMap(data => {
                                this.types = data[0];
                                this.types$.next(data[0]);
                                this.periods = data[1];
                                this.productCategories = data[2];
                                this.agentsService.productCategories$.next(this.productCategories);
                                return iif(() => !!data,
                                    this.commissionService.getCommission({ agentId: this.agent.Id }), EMPTY
                                );
                            }),
                        );
                })
            )
            .subscribe({
                next: (res: ICommissionInfo) => {
                    if (res) {
                        this.formulateAgentData(res);
                        if (this.viewCommissionsChangeHistoryPermission) {
                            this.getCommissionChangeHistory();
                        }
                    }
                    this.loading = false;
                },
                error: (err: HttpErrorResponse) => {
                    this.loading = false;
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public addCommissionPlan(): void {
        const ref = this.dialogService.open(AddCommissionPlanComponent, {
            header: this.translateService.instant('AddCommissionPlan'),
            data: {
                AgentId: +this.route.parent.snapshot.params.id,
                Periods: this.periods,
            },
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
        });

        ref.onClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res: ICommissionInfo) => {
                    if (res) {
                        this.agentCommissionInfo = res;
                        this.agentCommissionInfo.PeriodName =
                            this.periods.find(item => item.Value === res.Period).Name;
                        this.initColumnDefs();
                        this.initColumnDefsHistory();
                    }
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    private initColumnDefs(): void {
        this.columnDefs = [
            {
                field: 'ProductCategoryId',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Category'),
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: dataFormatterByValue.bind(this, this.agentsService.productCategories$),
                minWidth: 120,
            },
            {
                field: 'Type',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Type'),
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: dataFormatterByValue.bind(this, this.types$),
                minWidth: 120,
            },
            {
                field: 'Percent',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Percent'),
                sortable: true,
                filter: 'agTextColumnFilter',
                type: 'rightAligned',
                cellRenderer: params => {
                    return `${params.data.Percent !== null ? `${params.data.Percent} %` : ''}`;
                },
                cellClass: 'layout-ltr',
                minWidth: 120,
            },
            {
                field: 'actions',
                headerName: ' ',
                cellRendererSelector: params => {
                    return {
                        component: ActionsRendererComponent,
                        params: {
                            data: {
                                menuItems: [
                                    {
                                        label: this.translateService.instant('Edit'),
                                        icon: 'pi pi-fw icon-edit icon-size-sm',
                                        command: () => {
                                            this.openAddEditCommissionDialog(params?.data);
                                        }
                                    },
                                ],
                            }
                        }
                    };
                },
                menuTabs: [],
                suppressColumnsToolPanel: true,
                resizable: false,
                filter: false,
                sortable: false,
                minWidth: 40,
                maxWidth: 40,
            }
        ];

        if (this.isAdmin && (this.myProfileCommissionSection || !this.editCommissionPermission)) {
            this.columnDefs.pop();
        }
    }

    private initColumnDefsHistory(): void {
        this.columnDefsHistory = [
            {
                field: 'ProductCategoryId',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Category'),
                sortable: true,
                filter: 'agTextColumnFilter',
                valueFormatter: dataFormatterByValue.bind(this, this.agentsService.productCategories$),
                minWidth: 120,
            },
            {
                field: 'Type',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Type'),
                sortable: true,
                filter: 'agTextColumnFilter',
                minWidth: 120,
                valueFormatter: dataFormatterByValue.bind(this, this.types$),
            },
            {
                field: 'OldPercent',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'OldPercent'),
                sortable: true,
                filter: 'agTextColumnFilter',
                type: 'rightAligned',
                cellRenderer: params => {
                    return `${params.data.OldPercent !== null ? `<span>${params.data.OldPercent} %</span>` : ''}`;
                },
                cellClass: 'layout-ltr',
                minWidth: 120,
            },
            {
                field: 'NewPercent',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'NewPercent'),
                sortable: true,
                filter: 'agTextColumnFilter',
                type: 'rightAligned',
                cellRenderer: params => {
                    return `${params.data.NewPercent !== null ? `<span>${params.data.NewPercent} %</span>` : ''}`;
                },
                cellClass: 'layout-ltr',
                minWidth: 120,
            },
            {
                field: 'ChangeDate',
                headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'ChangeDate'),
                cellRenderer: DateCellRenderer,
                headerClass: 'center-aligned',
                cellClass: 'text-center',
                sortable: true,
                minWidth: 80,
            }
        ];
    }

    public openAddEditCommissionDialog(commission?: any): void {
        const data: any = {
            AgentId: +this.route.parent.snapshot.params.id,
            Key: 'AgentId',
            productCategories: this.productCategories,
            Types: this.types
        };
        if (commission) {
            data.commission = commission;
        }

        const ref = this.dialogService.open(AddEditCommissionComponent, {
            data,
            header: data.commission ?
                this.translateService.instant('EditCommission') : this.translateService.instant('AddCommission'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });

        ref.onClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (result: ICommission) => {
                    if (result) {
                        if (!this.commissions.length) {
                            this.commissionService.getCommission({ agentId: +this.route.parent.snapshot.params.id })
                                .subscribe({
                                    next: res => {
                                        this.formulateAgentData(res);
                                        if (this.viewCommissionsChangeHistoryPermission) {
                                            this.getCommissionChangeHistory();
                                        }
                                    }
                                });
                        } else {
                            const idx = this.commissions.findIndex(item => item.Id === result.Id);
                            if (idx !== -1) {
                                this.commissions[idx] = result;
                                this.commissions = [...this.commissions];
                            } else {
                                this.commissions.push(result);
                                this.commissions = [...this.commissions];
                            }
                            if (this.viewCommissionsChangeHistoryPermission) {
                                this.getCommissionChangeHistory();
                            }
                        }
                    }
                }
            });
    }

    private getCommissionChangeHistory(): void {
        this.commissionService.getCommissionHistory(this.commissionId)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                next: (res: ICommission[]) => {
                    this.commissionChangeHistory = res;
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    /**
     * @desc Gets table context menu items.
     * @param params
     * @returns {(string | MenuItemDef)[]} - items of context menu
     */
    public getContextMenuItems = (
        params: GetContextMenuItemsParams
    ): (string | MenuItemDef)[] => {
        return params.node &&
               (this.isAdmin && (this.myProfileCommissionSection || !this.editCommissionPermission)) ?
            [] : [
                {
                    name: this.translateService.instant('Edit'),
                    icon: '<i class="pi icon-edit icon-size-sm"></i>',
                    action: () => {
                        this.openAddEditCommissionDialog(params.node.data);
                    },
                }
            ];
    }

    private formulateAgentData(res: any): void {
        this.agentCommissionInfo = res;
        this.agentCommissionInfo.PeriodName = this.periods.find(item => item.Value === res.Period).Name;
        this.commissions = res.Commissions;
        this.commissionId = res.Id;
        this.initColumnDefs();
        this.initColumnDefsHistory();
    }
}
