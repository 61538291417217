<div class="table-wrapper table-wrapper-full-page-height">
    <ng-container *ngIf="isAdmin; else agentTemplate">
        <app-filters
            (selectedFilters)="getSelectedFilters($event)"
            [statesFromParent]="states"
            [selectedStateOption]="states[0]"
            [showStates]="true"
            [showCurrency]="false"
            [showPeriod]="false"
            [showPartner]="true"
            [showParent]="true"
        >
            <div *appCheckPermissions="permissionsService.permissions?.AgentPermissions?.AddAgent"
                 class="add-button-container">
                <button pButton pRipple [label]="'Add' | translate"
                        icon="pi pi-plus" class="p-button-primary add-btn"
                        (click)="openCreateDialog()">
                </button>
            </div>
        </app-filters>
    </ng-container>

    <ng-template #agentTemplate>
        <app-filters
                (selectedFilters)="getSelectedFilters($event)"
                [selectedParentOption]="this.parentId"
                [statesFromParent]="states"
                [selectedStateOption]="states[0]"
                [showStates]="true"
                [showCurrency]="false"
                [showPeriod]="false"
                [showPartner]="false"
                [showParent]="true"
        >
            <div *appCheckPermissions="permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.AddAgent"
                class="add-button-container">
                <button pButton pRipple [label]="'Add' | translate"
                        icon="pi pi-plus" class="p-button-primary add-btn"
                        (click)="openCreateDialog()">
                </button>
            </div>
        </app-filters>
    </ng-template>

    <app-ag-table
        [name]="tableName"
        [columnDefs]="columnDefs"
        [domLayout]="'normal'"
        [pagination]="true"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [rowModelType]="rowModelType"
        [serverSideStoreType]="serverSideStoreType"
        [getContextMenuItems]="getContextMenuItems"
        [dataSource]="dataSource"
        [sideBar]="null"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [treeData]="true"
        [defaultColDef]="defaultColDef"
        [isServerSideGroup]="isServerSideGroup"
        [ignoreDefaultExport]="isAdmin"
        [getServerSideGroupKey]="getServerSideGroupKey"
        (rowGroupOpened)="onRowGroupOpened($event)"
    ></app-ag-table>
</div>
