import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

import { HttpService } from '@core/services';

import { IEnum, IResponseWithCount, ITask, ITaskUrl } from '@core/interfaces';
import { Methods, TaskStatesEnum } from '@core/enums';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})
export class TasksService {

    public taskStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public taskTypes$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public taskNotifications$: BehaviorSubject<ITask[]> = new BehaviorSubject<ITask[]>([]);

    constructor(
        private http: HttpService,
    ) {
        this.loadTaskStates();
        this.loadTaskTypes();
    }

    private loadTaskStates(): void {
        if (localStorage.getItem('enum_task-states')) {
            this.taskStates$.next(JSON.parse(localStorage.getItem('enum_task-states')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_TASK_STATES
            )
                .subscribe((res: IEnum[]) => {
                    res.map(status => {
                        status.Background = status.Value === TaskStatesEnum.Pending ? '#F2F2F2' :
                            status.Value === TaskStatesEnum.InProgress ? '#64B5F7' :
                                status.Value === TaskStatesEnum.Completed ? '#79F2B8' :
                                    status.Value === TaskStatesEnum.Failed ? '#EE6464' : '#FFD6DA';
                        status.Color = '#3F3F3F';
                        status.TextColor = status.Value === TaskStatesEnum.Pending ? '#858593' :
                            status.Value === TaskStatesEnum.InProgress ? '#64B5F7' :
                                status.Value === TaskStatesEnum.Completed ? '#0FBF6B' :
                                    status.Value === TaskStatesEnum.Failed ? '#EE6464' : '#F37E7E';
                    });
                    localStorage.setItem('enum_task-states', JSON.stringify(res));
                    this.taskStates$.next(res);
                });
        }
    }

    private loadTaskTypes(): void {
        if (localStorage.getItem('enum_task-types')) {
            this.taskTypes$.next(JSON.parse(localStorage.getItem('enum_task-types')));
        } else {
            this.http.request(
                'get',
                environment.ApiUrl + Methods.GET_TASK_TYPES
            )
                .subscribe((res: IEnum[]) => {
                    localStorage.setItem('enum_task-types', JSON.stringify(res));
                    this.taskTypes$.next(res);
                });
        }
    }

    public getTasks(payload: any): Observable<IResponseWithCount<ITask>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_TASKS,
            payload
        );
    }

    public getTaskUrl(taskId: number): Observable<ITaskUrl> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_TASK_URL,
            null,
            false,
            { params: { taskId } },
        );
    }

    public cancelTask(id: number): Observable<void> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.CANCEL_TASK,
            null,
            false,
            { params: { id } }
        );
    }

}
