import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { defer, EMPTY, iif, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import {
    ColDef,
    ColGroupDef,
    GetContextMenuItemsParams,
    ICellRendererParams,
    IServerSideDatasource,
    MenuItemDef,
    MenuItemLeafDef,
    RowGroupOpenedEvent,
    RowNode,
    ServerSideStoreType,
    SortModelItem,
} from 'ag-grid-community';
import { IServerSideGetRowsParams } from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource';
import { DialogService } from 'primeng/dynamicdialog';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import {
    AgentsService,
    AuthService,
    LanguageService,
    PermissionsService,
    ToastService,
} from '@core/services';
import {
    ActionsRendererComponent,
    AmountRendererComponent,
    ChangePasswordDialogComponent,
    ConfirmationDialogComponent,
    DateCellRenderer,
    LinkCellComponent,
    MemberStateRenderer,
    TransferDialogComponent,
    AddEditAgentCommentDialogComponent
} from '@app/shared';
import { CreateAgentComponent, MoveAgentComponent } from '@pages/agents/containers';
import { IAgent, IChangePassword, IEnum, IFilter, ITransfer } from '@core/interfaces';
import { MemberStatesEnum, TransactionTypesEnum } from '@core/enums';
import { generateColumnDefs } from '@core/utilities';

export let AGENTS_TABLE_PARAMS: IServerSideGetRowsParams = null;

@Component({
    selector: 'app-agents',
    templateUrl: './agents.component.html',
    styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit, OnDestroy {
    /** @desc Table name to store column definitions in the local storage. */
    public tableName: string = 'agents';
    public paginationPageSize: number = 50;
    public cacheBlockSize: number = 50;
    private filterObj: IFilter;
    public serverSideStoreType: ServerSideStoreType = 'partial';

    /** @desc The Client-Side Row Model deals with client-side data.
     *  The Server-Side, Infinite and Viewport Row Models deal with server-side data.
     */
    public rowModelType: string = 'serverSide';

    /** @desc Table row parameters. */
    public params: IServerSideGetRowsParams;

    /** @desc The list of Columns in gridOptions.columnDefs can be a mix of Columns and Column Groups.
     *  You can mix and match at will, every level can have any number of Columns and Column Groups and in any order.
     */
    public columnDefs: Array<ColDef | ColGroupDef> = [
        {
            field: 'UserName',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Username'),
            headerTooltip: this.translateService.instant('Username'),
            cellRenderer: MemberStateRenderer,
            cellRendererParams: {
                rendererKey: 'Status'
            },
            sortable: true,
            minWidth: 140,
        },
        {
            field: 'RegistrationDate',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'RegistrationDate'),
            headerTooltip: this.translateService.instant('RegistrationDate'),
            cellRenderer: DateCellRenderer,
            headerClass: 'center-aligned',
            cellClass: 'text-center',
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'Role',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Role'),
            headerTooltip: this.translateService.instant('Role'),
            minWidth: 180,
        },
        {
            field: 'Currency',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Currency'),
            headerTooltip: this.translateService.instant('Currency'),
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'RealBalance',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Balance'),
            headerTooltip: this.translateService.instant('Balance'),
            cellRenderer: AmountRendererComponent,
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'NetworkBalance',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'AgentNetworkBalance'),
            headerTooltip: this.translateService.instant('AgentNetworkBalance'),
            cellRenderer: AmountRendererComponent,
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'EarningBalance',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'EarningBalance'),
            headerTooltip: this.translateService.instant('EarningBalance'),
            cellRenderer: AmountRendererComponent,
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'LastPaidAmount',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'LastPaidAmount'),
            headerTooltip: this.translateService.instant('LastPaidAmount'),
            cellRenderer: AmountRendererComponent,
            type: 'rightAligned',
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'PendingEarnings',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'PendingEarning'),
            headerTooltip: this.translateService.instant('PendingEarning'),
            cellRenderer: AmountRendererComponent,
            type: 'rightAligned',
            sortable: true,
            minWidth: 80,
        },
        {
            field: 'PlayersCount',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'PlayerCount'),
            headerTooltip: this.translateService.instant('PlayerCount'),
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'PlayersBalance',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'PlayersNetworkBalance'),
            headerTooltip: this.translateService.instant('PlayersNetworkBalance'),
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'SubAgentCount',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'SubAgentCount'),
            headerTooltip: this.translateService.instant('SubAgentCount'),
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'Comment',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Comment'),
            headerTooltip: this.translateService.instant('Comment'),
            tooltipField: 'Comment',
            type: 'rightAligned',
            minWidth: 80,
        },
        {
            field: 'actions',
            headerName: ' ',
            cellRendererSelector: params => {
                return {
                    component: ActionsRendererComponent,
                    params: {
                        data: {
                            menuItems: this.getActionsColumnItemsBasedOnPermissions(params)
                        }
                    }
                };
            },
            menuTabs: [],
            suppressColumnsToolPanel: true,
            resizable: false,
            minWidth: 40,
            maxWidth: 40,
        },
    ];

    public defaultColDef: ColDef = {
        flex: 1,
        floatingFilter: false,
        resizable: true,
    };

    public autoGroupColumnDef: ColDef = {
        field: 'Id',
        headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'ID'),
        headerTooltip: this.translateService.instant('ID'),
        cellRendererParams: {
            innerRenderer: LinkCellComponent,
            text: (params: ICellRendererParams) => `${params.data.Id}`,
            link: (params: ICellRendererParams) => {
                return `${params.data.Id}`;
            },
        },
        sortable: true,
        minWidth: 140,
        maxWidth: 600,
    };

    /** @desc An object containing the method of getting server-side data of the table. */
    public dataSource: IServerSideDatasource = {
        getRows: (params: IServerSideGetRowsParams) => {
            this.params = params;
            AGENTS_TABLE_PARAMS = params;

            if (!this.isAdmin || (this.isAdmin && this.filterObj.PartnerId)) {
                params.api.hideOverlay();
                this.getAgents(this.filterObj, params);
            } else {
                params.api.showNoRowsOverlay();
                params.success({
                    rowData: [],
                    rowCount: 0
                });
            }
        },
    };

    public states: Partial<IEnum>[];
    public agents: IAgent[];
    public isAdmin: boolean = environment.role === 'admin';
    public parentId: number = this.isAdmin ? null : this.authService.userGetter().Id;
    private sortModelState: SortModelItem[] = [];

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private languageService: LanguageService,
        private agentsService: AgentsService,
        private authService: AuthService,
        private toastService: ToastService,
        public permissionsService: PermissionsService,
    ) {
        this.columnDefs = generateColumnDefs(this.tableName, this.columnDefs);
    }

    ngOnInit(): void {
        this.getStates();

        if (this.isAdmin) {
            this.addColumnDependingOnRole();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    public getSelectedFilters(filterFields: IFilter): void {
        this.filterObj = filterFields;
        this.params?.api.paginationGoToPage(0);
        this.params?.api.onFilterChanged();
    }

    private addColumnDependingOnRole(): void {
        this.columnDefs.splice(3, 0, {
            field: 'Partner',
            headerValueGetter: this.languageService.localizeTableHeader.bind(this, 'Partner'),
            headerTooltip: this.translateService.instant('Partner'),
            cellRenderer: LinkCellComponent,
            cellRendererParams: {
                text: (cellParams: ICellRendererParams) => `${cellParams.data.PartnerName}`,
                link: (cellParams: ICellRendererParams) => `/partners/${cellParams.data.PartnerId}`
            },
            sortable: true,
            minWidth: 100,
        });
    }

    private getAgents(filterObj: IFilter, params: IServerSideGetRowsParams): void {

        let id: number | null = this.parentId;

        if (params.request.groupKeys.length) {
            id = +params.parentNode.key;
        } else if (filterObj.ParentId) {
            id = filterObj.ParentId;
        }

        /** Whenever we had autoGroupColumnDef in the table (tree table), it ignores the 'field' value of the ColDef
         *  and sets its name 'ag-Grid-AutoColumn'. So in this case, if we want sort and filter functionality work
         *  properly, we need to rename its key name in filterModel & sortModel, before making API call
         */
        if (params.request.sortModel[0]?.colId === 'ag-Grid-AutoColumn') {
            params.request.sortModel[0].colId = this.autoGroupColumnDef.field;
        }

        /** As we have a tree table here, current code line helps us to have a sortModelState, which is later being used
         * to get tree sub items with a sort model, which was used to get main table
         */
        this.sortModelState = params.request.sortModel;

        const payload = {
            ...filterObj,
            Id: params.request.groupKeys.length && filterObj.Id ? null : filterObj.Id,
            ParentId: id,
            SkipCount: params.request.endRow / this.paginationPageSize - 1,
            TakeCount: this.paginationPageSize,
            filterModel: {},
            sortModel: this.sortModelState,
        };

        this.agentsService.getAgentTree(id, payload)
            .pipe(
                take(1),
                map(res => {
                    res.Entities = res.Entities.map(agent => {
                        return {
                            ...agent,
                            group: agent.SubAgentCount ? agent.Children = [] : null
                        };
                    });
                    return res;
                })
            )
            .subscribe({
                next: res => {
                    params.success({
                        rowData: res.Entities,
                        rowCount: res.Count,
                    });
                    if (!res.Entities.length) {
                        params.api.showNoRowsOverlay();
                    } else {
                        params.api.hideOverlay();
                    }
                    params.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
                },
                error: (err: HttpErrorResponse) => {
                    params.success({
                        rowData: [],
                        rowCount: 0,
                    });
                    params.api.showNoRowsOverlay();
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });

    }

    public isServerSideGroup = (data: any) => {
        return data.group;
    }

    public getServerSideGroupKey = (data: any) => {
        return data.Id;
    }

    private getStates(): void {
        this.agentsService.agentStates$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(res => {
                const all = { Value: null, Name: 'All' };
                if (res) {
                    this.states = [all, ...res];
                }
            });
    }


    public onRowGroupOpened(e: RowGroupOpenedEvent): void {
        e.api.sizeColumnsToFit();
        /** @desc  Auto size column only after third level row opening. */
        if (e.node.uiLevel > 1) {
            e.columnApi.autoSizeColumn('ag-Grid-AutoColumn');
        }
    }

    public openCreateDialog(): void {
        const ref = this.dialogService.open(CreateAgentComponent, {
            header: this.translateService.instant('AddAgent'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                if (result) {
                    this.params?.api.refreshServerSideStore();
                }
            });
    }

    private openTransferDialog(paramsData: any): void {
        const ref = this.dialogService.open(TransferDialogComponent, {
            header: this.translateService.instant('Transfer'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding topbar-transfer-dialog',
            data: {
                paramsData,
                showTransactionType: true,
                showAgentsDropdown: true,
                showFromAgentDropdown: true,
                TransactionTypeId: TransactionTypesEnum.Deposit,
                PartnerId: paramsData.PartnerId
            }
        });
        ref.onClose
            .pipe(
                filter(res => !!res),
                switchMap((modalData: any) => {
                    const data: ITransfer = {
                        TransactionTypeId: modalData.TransactionTypeId,
                        PartnerId: paramsData.PartnerId,
                        ...modalData
                    };
                    return iif(() => modalData, this.agentsService.moneyTransfer(data), EMPTY);
                }),
                take(1)
            )
            .subscribe({
                next: () => {
                    this.toastService.showToastMsg('success', 'SuccessfullySentMessage');
                    this.authService.getCurrentProfile();
                    this.params?.api?.refreshServerSide();
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    private openLimitDialog(paramsData: IAgent, TransactionTypeId: number): void {
        const isPayout = TransactionTypeId === TransactionTypesEnum.Payout;
        const ref = this.dialogService.open(TransferDialogComponent, {
            header: this.translateService.instant(isPayout ? 'Payout' : 'OpenLimit'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
            data: {
                paramsData,
                TransactionTypeId,
                Balance: isPayout ? paramsData.EarningBalance : paramsData.RealBalance,
                dialogInfo: [
                    {
                        Data: paramsData.UserName,
                        HasNavigation: false,
                        Icon: 'pi icon-user icon-size-lg',
                        Title: this.translateService.instant('Username'),
                    },
                    {
                        Data: paramsData.Id,
                        HasNavigation: true,
                        Icon: 'pi icon-client_type icon-size-lg',
                        Title: this.translateService.instant('AgentId'),
                    },
                    {
                        Data: paramsData.RealBalance,
                        HasNavigation: false,
                        Icon: 'pi icon-store_balance icon-size-lg',
                        Title: this.translateService.instant('Balance'),
                    },
                    ...(this.isAdmin && isPayout ?
                        [{
                            Data: paramsData.EarningBalance,
                            HasNavigation: false,
                            Icon: 'pi icon-store_balance icon-size-lg',
                            Title: this.translateService.instant('EarningBalance'),
                        }] : [])
                ]

            }
        });
        ref.onClose
            .pipe(
                filter(res => !!res),
                switchMap((modalData: any) => {
                    const data: Partial<ITransfer> = {
                        TransactionTypeId,
                        ToAgentId: paramsData.Id,
                        ...modalData
                    };
                    if (this.isAdmin) {
                        data.FromUserId = this.authService.userData$.getValue().Id;
                    } else {
                        data.FromAgentId = this.authService.userData$.getValue().Id;
                    }
                    return defer(() => modalData && !isPayout ?
                        this.agentsService.transfer(data) : this.agentsService.payout(data));
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                this.toastService.showToastMsg('success', 'SuccessfullySentMessage');
                this.getAgents(this.filterObj, this.params);
                this.authService.getCurrentProfile();
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    private openAddCommentDialog(paramsData: IAgent): void {
        const HasComment: boolean = !!paramsData.Comment;

        const ref = this.dialogService.open(AddEditAgentCommentDialogComponent, {
            header: this.translateService.instant(HasComment ? 'EditComment' : 'AddComment'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
            data: {
                AgentID: paramsData.Id,
                HasComment,
                Comment: paramsData.Comment
            }
        });
        ref.onClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                if (result) {
                    this.getAgents(this.filterObj, this.params);
                }
            });
    }

    public openChangePasswordDialog(paramsData: IAgent): void {
        const ref = this.dialogService.open(ChangePasswordDialogComponent, {
            header: this.translateService.instant('ChangePassword'),
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((modalData: any) => {
                    const changePass: IChangePassword = {
                        Id: paramsData.Id,
                        ...modalData
                    };
                    return iif(() => modalData, this.agentsService.changePassword(changePass), EMPTY);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe({
                next: () => {
                    this.toastService.showToastMsg('success', 'PasswordSuccessfullyChanged');
                },
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    public openConfirmationDialog(params, paramsData): void {
        const Status = paramsData.Status === MemberStatesEnum.Active ? MemberStatesEnum.Blocked : MemberStatesEnum.Active;
        const ref = this.dialogService.open(ConfirmationDialogComponent, {
            header: this.translateService.instant('Confirmation'),
            data: {
                text: paramsData.Status === MemberStatesEnum.Active
                    ? this.translateService.instant('BlockConfirmText')
                    : this.translateService.instant('UnblockConfirmText')
            },
            width: '40%',
            styleClass: 'dialog-with-footer'
        });
        ref.onClose
            .pipe(
                switchMap((isConfirmed: boolean) => {
                    const data = {
                        Id: paramsData.Id,
                        Status
                    };

                    return iif(() => isConfirmed, this.agentsService.changeStatus(data), EMPTY);
                }),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(() => {
                paramsData.Status = Status;
                params.api.redrawRows({ rowNodes: [params.node] });
                this.toastService.showToastMsg('success', 'UpdateSuccessMessage', 'Agent');
            }, (err: HttpErrorResponse) => {
                this.toastService.showToastMsg('error', err?.error?.message);
            });
    }

    public openMoveAgentDialog(paramsData: any): void {
        const ref = this.dialogService.open(MoveAgentComponent, {
            header: this.translateService.instant('MoveAgent'),
            width: '40%',
            styleClass: 'dialog-with-footer dialog-without-padding',
            data: {
                PartnerId: paramsData.PartnerId,
                AgentId: paramsData.Id,
                dialogInfo: [
                    {
                        Data: paramsData.UserName,
                        HasNavigation: false,
                        Icon: 'pi icon-user icon-size-lg',
                        Title: this.translateService.instant('AgentUsername'),
                    },
                    {
                        Data: paramsData.Id,
                        HasNavigation: false,
                        Icon: 'pi icon-client_type icon-size-lg',
                        Title: this.translateService.instant('AgentID'),
                    },
                ]
            }
        });
        ref.onClose
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(result => {
                if (result) {
                    this.params?.api.refreshServerSideStore();
                }
            });
    }

    public generateAgentTreeReport(node: RowNode): void {
        const partnerId = node.data.PartnerId;
        this.agentsService.generateAgentTreeReport(partnerId)
            .pipe(take(1))
            .subscribe({
                next: () => this.toastService.showToastMsg('info', 'ProcessStartSuccessMessage', 'ExportAgentTree'),
                error: (err: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', err?.error?.message);
                }
            });
    }

    /**
     * @desc Gets table context menu items.
     * @param params
     * @returns {(string | MenuItemLeafDef)[]} - items of context menu
     */
    public getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemLeafDef)[] => {
        if (!params.node) {
            return [];
        }

        return this.getContextMenuItemsBasedOnPermissions(params);
    }

    private getContextMenuItemsBasedOnPermissions(params: GetContextMenuItemsParams): (string | MenuItemLeafDef)[] {
        const contextMenuItems: MenuItemDef[] = [
            {
                name: this.translateService.instant('Properties'),
                icon: '<i class="pi icon-info icon-size-sm"></i>',
                action: () => {
                    this.router.navigate([`/agents/${params.node.data.Id}`]);
                }
            },
            {
                name: this.translateService.instant(params.node.data.Comment ? 'Edit Comment' : 'Add Comment'),
                icon: params.node.data.Comment
                    ? '<i class="pi icon-edit icon-size-sm"></i>'
                    : '<i class="pi icon-plus icon-size-sm"></i>',
                action: () => {
                    this.openAddCommentDialog(params.node.data);
                }
            }
        ];

        if (this.isAdmin) {
            /** @desc When user has 'Admin' role, he must not be able to change any Agent data except first level Agents. */
            if (!params.node.data.ParentId) {
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.ChangeAgentStatus)) {
                    contextMenuItems.push(
                        {
                            name: params.node?.data.Status === MemberStatesEnum.Active
                                ? this.translateService.instant('Block')
                                : this.translateService.instant('Unblock'),
                            icon: params.node?.data.Status === MemberStatesEnum.Active
                                ? '<i class="pi icon-lock icon-size-sm"></i>'
                                : '<i class="pi icon-unlock icon-size-sm"></i>',
                            action: () => {
                                this.openConfirmationDialog(params, params.node.data);
                            },
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.MakeAgentTransfer)) {
                    contextMenuItems.push(
                        {
                            name: this.translateService.instant('Deposit'),
                            icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                            action: () => {
                                this.openLimitDialog(params.node.data, TransactionTypesEnum.Deposit);
                            }
                        },
                        {
                            name: this.translateService.instant('Withdraw'),
                            icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                            action: () => {
                                this.openLimitDialog(params.node.data, TransactionTypesEnum.Withdraw);
                            },
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.MakeAgentPayout)) {
                    contextMenuItems.push(
                        {
                            name: this.translateService.instant('Payout'),
                            icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                            action: () => {
                                this.openLimitDialog(params.node.data, TransactionTypesEnum.Payout);
                            },
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.ChangeAgentPassword)) {
                    contextMenuItems.push(
                        {
                            name: this.translateService.instant('ChangePassword'),
                            icon: '<i class="pi icon-password icon-size-sm"></i>',
                            action: () => {
                                this.openChangePasswordDialog(params.node.data);
                            }
                        }
                    );
                }
            }

            if (this.permissionsService.checkPermission(this.permissionsService.permissions
                ?.AgentPermissions?.MoveAgent)) {
                contextMenuItems.push(
                    {
                        name: this.translateService.instant('Move'),
                        icon: '<i class="pi icon-move icon-size-sm""></i>',
                        action: () => {
                            this.openMoveAgentDialog(params.node.data);
                        },
                    }
                );
            }

            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentPermissions?.AgentMoneyTransfer)) {
                contextMenuItems.splice(-1, 0,
                    {
                        name: this.translateService.instant('Transfer'),
                        icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                        action: () => {
                            this.openTransferDialog(params.node.data);
                        },
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentPermissions.ExportAgentTree)) {
                contextMenuItems.push(
                    {
                        name: this.translateService.instant('GenerateReport'),
                        icon: '<i class="pi icon-tasks icon-size-sm"></i>',
                        action: () => {
                            this.generateAgentTreeReport(params.node);
                        }
                    },
                );
            }
        } else {
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.BlockAgent)) {
                contextMenuItems.push(
                    {
                        name: params.node?.data.Status === MemberStatesEnum.Active
                            ? this.translateService.instant('Block')
                            : this.translateService.instant('Unblock'),
                        icon: params.node?.data.Status === MemberStatesEnum.Active
                            ? '<i class="pi icon-lock icon-size-sm"></i>'
                            : '<i class="pi icon-unlock icon-size-sm"></i>',
                        action: () => {
                            this.openConfirmationDialog(params, params.node.data);
                        },
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.DepositToAgent)) {
                contextMenuItems.push(
                    {
                        name: this.translateService.instant('Deposit'),
                        icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                        action: () => {
                            this.openLimitDialog(params.node.data, TransactionTypesEnum.Deposit);
                        }
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.WithdrawalFromAgent)) {
                contextMenuItems.push(
                    {
                        name: this.translateService.instant('Withdraw'),
                        icon: '<i class="pi icon-transactions icon-size-sm"></i>',
                        action: () => {
                            this.openLimitDialog(params.node.data, TransactionTypesEnum.Withdraw);
                        },
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.ChangeAgentPassword)) {
                contextMenuItems.push(
                    {
                        name: this.translateService.instant('ChangePassword'),
                        icon: '<i class="pi icon-password icon-size-sm"></i>',
                        action: () => {
                            this.openChangePasswordDialog(params.node.data);
                        }
                    }
                );
            }
        }

        return contextMenuItems;
    }

    private getActionsColumnItemsBasedOnPermissions(params: ICellRendererParams): MenuItem[] {
        const actionsColumnItems: MenuItem[] = [
            {
                label: this.translateService.instant('Properties'),
                icon: 'pi pi-fw icon-info icon-size-sm',
                command: () => {
                    this.router.navigate([`/agents/${params.data.Id}`]);
                }
            },
            {
                separator: true
            },
            {
                label: this.translateService.instant(params.data.Comment ? 'EditComment' : 'AddComment'),
                icon: params.data.Comment ? 'pi pi-fw icon-edit icon-size-sm' : 'pi pi-fw icon-plus icon-size-sm',
                command: () => {
                    this.openAddCommentDialog(params.data);
                }
            },
        ];

        if (this.isAdmin) {
            /** When user has 'Admin' role, he must not ba able to change any Agent data except first level Agents */
            if (!params.data.ParentId) {
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.ChangeAgentStatus)) {
                    actionsColumnItems.push(
                        {
                            separator: true
                        },
                        {
                            label: params.data.Status === MemberStatesEnum.Active
                                ? this.translateService.instant('Block')
                                : this.translateService.instant('Unblock'),
                            icon: params.data.Status === MemberStatesEnum.Active ? 'pi pi-fw icon-lock icon-size-sm'
                                : 'pi pi-fw icon-unlock icon-size-sm',
                            command: () => {
                                this.openConfirmationDialog(params, params.data);
                            }
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.MakeAgentTransfer)) {
                    actionsColumnItems.push(
                        {
                            separator: true
                        },
                        {
                            label: this.translateService.instant('Deposit'),
                            icon: 'pi pi-fw icon-transactions icon-size-sm',
                            command: () => {
                                this.openLimitDialog(params.data, TransactionTypesEnum.Deposit);
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: this.translateService.instant('Withdraw'),
                            icon: 'pi pi-fw icon-transactions icon-size-sm',
                            command: () => {
                                this.openLimitDialog(params.data, TransactionTypesEnum.Withdraw);
                            }
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.MakeAgentPayout)) {
                    actionsColumnItems.push(
                        {
                            separator: true
                        },
                        {
                            label: this.translateService.instant('Payout'),
                            icon: 'pi pi-fw icon-transactions icon-size-sm',
                            command: () => {
                                this.openLimitDialog(params.data, TransactionTypesEnum.Payout);
                            }
                        }
                    );
                }
                if (this.permissionsService
                    .checkPermission(this.permissionsService.permissions?.AgentPermissions?.ChangeAgentPassword)) {
                    actionsColumnItems.push(
                        {
                            separator: true
                        },
                        {
                            label: this.translateService.instant('ChangePassword'),
                            icon: 'pi pi-fw icon-password icon-size-sm',
                            command: () => {
                                this.openChangePasswordDialog(params.data);
                            }
                        }
                    );
                }
            }

            if (this.permissionsService.checkPermission(this.permissionsService.permissions
                ?.AgentPermissions?.MoveAgent)) {
                actionsColumnItems.push(
                    {
                        label: this.translateService.instant('Move'),
                        icon: 'pi icon-move icon-size-sm',
                        command: () => {
                            this.openMoveAgentDialog(params.node.data);
                        },
                    }
                );
            }

            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentPermissions?.AgentMoneyTransfer)) {
                actionsColumnItems.splice(-1, 0, ...[
                    {
                        label: this.translateService.instant('Transfer'),
                        icon: 'pi icon-transactions icon-size-sm',
                        command: () => {
                            this.openTransferDialog(params.data);
                        },
                    },
                    {
                        separator: true
                    }
                ]);
            }
            if (this.permissionsService.checkPermission(this.permissionsService.permissions?.AgentPermissions.ExportAgentTree)) {
                actionsColumnItems.push(
                    {
                        label: this.translateService.instant('GenerateReport'),
                        icon: 'pi icon-tasks icon-size-sm',
                        command: () => {
                            this.generateAgentTreeReport(params.node);
                        },
                    },
                );
            }
        } else {
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.BlockAgent)) {
                actionsColumnItems.push(
                    {
                        separator: true
                    },
                    {
                        label: params.data.Status === MemberStatesEnum.Active
                            ? this.translateService.instant('Block')
                            : this.translateService.instant('Unblock'),
                        icon: params.data.Status === MemberStatesEnum.Active ? 'pi pi-fw icon-lock icon-size-sm'
                            : 'pi pi-fw icon-unlock icon-size-sm',
                        command: () => {
                            this.openConfirmationDialog(params, params.data);
                        }
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.DepositToAgent)) {
                actionsColumnItems.push(
                    {
                        separator: true
                    },
                    {
                        label: this.translateService.instant('Deposit'),
                        icon: 'pi pi-fw icon-transactions icon-size-sm',
                        command: () => {
                            this.openLimitDialog(params.data, TransactionTypesEnum.Deposit);
                        }
                    },
                    {
                        separator: true
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.WithdrawalFromAgent)) {
                actionsColumnItems.push(
                    {
                        label: this.translateService.instant('Withdraw'),
                        icon: 'pi pi-fw icon-transactions icon-size-sm',
                        command: () => {
                            this.openLimitDialog(params.data, TransactionTypesEnum.Withdraw);
                        }
                    },
                    {
                        separator: true
                    }
                );
            }
            if (this.permissionsService
                .checkPermission(this.permissionsService.permissions?.AgentEnvironmentPermissions?.AgentPermissions?.ChangeAgentPassword)) {
                actionsColumnItems.push(
                    {
                        separator: true
                    },
                    {
                        label: this.translateService.instant('ChangePassword'),
                        icon: 'pi pi-fw icon-password icon-size-sm',
                        command: () => {
                            this.openChangePasswordDialog(params.data);
                        }
                    }
                );
            }
        }

        // {
        //     separator: true
        // },
        // {
        //     label: this.translateService.instant('MoveAgent'),
        //     icon: 'pi pi-fw icon-tree',
        //     command: ($event) => {
        //         // TODO Should be added delete logic
        //     }
        // },

        return actionsColumnItems;
    }
}
