import { Component } from '@angular/core';

import { MenuItem } from 'primeng/api';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-actions-component',
    template: `
        <div class="icon-wrapper" *ngIf="menuItems.length">
            <i class="pi icon-more icon-size-md" style="cursor: pointer;" (click)="menu.toggle($event)"></i>
        </div>
        <p-menu #menu [popup]="true" [model]="menuItems" appendTo="body"></p-menu>
    `
})

export class ActionsRendererComponent implements ICellRendererAngularComp {
    public menuItems: MenuItem[];

    constructor() {
    }
    agInit(params: ICellRendererParams): void {
        this.getCellParams(params);
    }

    // gets called whenever the cell refreshes
    refresh(params: ICellRendererParams): any {
        // set value into cell again
        this.getCellParams(params);
    }

    getCellParams(params: ICellRendererParams): void {
        this.menuItems = params.data.menuItems;
    }
}
