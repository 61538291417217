<div *ngIf="agentDetail"
     class="inner-page-main-section"
     [ngClass]="{'m-inner-page-main-section' : !isDesktopView}">
    <div class="p-fluid p-formgrid grid form">
        <div class="col-12 md:col-6 field-section">
            <div>
                <div class="field">
                    <div class="field-name">{{ 'FirstName' | translate }}</div>
                    <div class="field-value">{{ agentDetail.FirstName ? agentDetail.FirstName : '-' }}</div>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'LastName' | translate }}</div>
                    <div class="field-value">{{ agentDetail.LastName ? agentDetail.LastName : '-' }}</div>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'PhoneNumber' | translate }}</div>
                    <div class="field-value flex align-items-center layout-ltr">
                        <i class="pi icon icon-phone icon-size-sm text-primary mx-2"></i>
                        {{ agentDetail.PhoneNumber ? agentDetail.PhoneNumber : '-' }}</div>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'BankName' | translate }}</div>
                    <div class="field-value">{{ agentDetail.BankName ? agentDetail.BankName : '-' }}</div>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'BankAccountNumber' | translate }}</div>
                    <div
                        class="field-value">{{ agentDetail.BankAccountNumber ?
                        agentDetail.BankAccountNumber : '-' }}</div>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-6 field-section">
            <div>
                <div class="field">
                    <div class="field-name">{{ 'Status' | translate }}</div>
                    <app-status-view [statusDetail]="agentDetail.StatusDetail"
                                     [translationPrefix]="'EnumAgentState'">
                    </app-status-view>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'ParentID' | translate }}</div>
                    <ng-container *ngIf="agentDetail.ParentId; else noParentIdBlock">
                        <a [routerLink]="['/agents', agentDetail.ParentId, 'personal-details']" class="link">
                            {{ agentDetail.ParentId }}
                        </a>
                    </ng-container>
                    <ng-template #noParentIdBlock>-</ng-template>
                </div>
            </div>
            <div>
                <div class="field">
                    <div class="field-name">{{ 'ParentUsername' | translate }}</div>
                    <ng-container *ngIf="agentDetail.ParentUserName; else noParentUserNameBlock">
                        <a [routerLink]="['/agents', agentDetail.ParentId, 'personal-details']" class="link">
                            {{ agentDetail.ParentUserName }}
                        </a>
                    </ng-container>
                    <ng-template #noParentUserNameBlock>-</ng-template>
                </div>
            </div>
            <div>
                <div class="field custom-field-section">
                    <div class="field-name">{{ 'Role' | translate }}</div>
                    <div class="field-value">{{ agentDetail.Role ?
                        agentDetail.Role : '-' }}</div>
                </div>
            </div>
            <div>
                <div class="field custom-field-section">
                    <div class="field-name">{{ 'TimeZone' | translate }}</div>
                    <div class="field-value">
                        <app-time-zone-container
                            [onlyTimeZoneView]="true"
                            [timeZoneOffset]="agentDetail.TimeZone">
                        </app-time-zone-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
