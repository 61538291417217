import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

import { environment } from '@env/environment';
import { Methods } from '@core/enums';
import { HttpService, ToastService, UpdateStorageService } from '@core/services/index';
import {
    IAgent,
    IAgentComment,
    IAgentDetail,
    IAgentPermissionsInfo,
    IBalanceTransfer,
    ICommonSelectItem,
    IEarning,
    IEnum,
    IParent,
    IResponseWithCount,
    IRole,
    IUserTransaction,
    IMoveAgent,
} from '@core/interfaces';

@Injectable({
    providedIn: 'root'
})

export class AgentsService {

    public agentStates$: BehaviorSubject<IEnum[]> = new BehaviorSubject<IEnum[]>([]);
    public agentInfo$: BehaviorSubject<IAgentDetail> = new BehaviorSubject<IAgentDetail>(null);
    public earningTypes$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject([]);
    public productCategories$: BehaviorSubject<ICommonSelectItem[]> = new BehaviorSubject<ICommonSelectItem[]>(null);

    private unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private http: HttpService,
        private router: Router,
        private toastService: ToastService,
        private updateStorageService: UpdateStorageService,
    ) {
        this.subscribeToConfigVersionChanges();
    }

    private subscribeToConfigVersionChanges(): void {
        this.updateStorageService.configVersionHasChanged$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(() => {
                this.loadAgentStatuses();
                this.getEarningTypes();
            });
    }

    public getAgentTree(parentId, payload): Observable<IResponseWithCount<IAgent>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_AGENT_TREE_BY_PARENT_ID,
            payload,
        );
    }

    public loadAgentStatuses(): void {
        if (localStorage.getItem('enum_agent-states')) {
            this.agentStates$.next(JSON.parse(localStorage.getItem('enum_agent-states')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_AGENT_STATUSES)
                .subscribe(res => {
                    res.forEach((status: IEnum) => {
                        status.Background = status.Name === 'Active' ? '#79F2B8' :
                            status.Name === 'Inactive' ? '#EE6464' : '#E3E3E8';
                        status.Color = (status.Name === 'Active' || status.Name === 'Inactive') ? '#3F3F3F' : '#B3B3BC';
                    });
                    localStorage.setItem('enum_agent-states', JSON.stringify(res));
                    this.agentStates$.next(res);
                });
        }

    }

    public getAgentById(agentId: number): void {
        this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_AGENT_BY_ID,
            null,
            false,
            { params: { agentId } })
            .pipe(
                map(agent => {
                    this.agentInfo$.next(agent);
                }),
                catchError((e: HttpErrorResponse) => {
                    this.toastService.showToastMsg('error', e?.error?.message);
                    if (e.status !== 401) {
                        this.router.navigate(['/agents']);
                    }
                    return of(e);
                })
            ).subscribe();
    }

    public changeAgentDetail(detail: IAgentDetail): Observable<IAgentDetail> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_AGENT_DETAIL, detail);
    }

    public changePassword(data: any): Observable<any> {
        return this.http.request('put', environment.ApiUrl + Methods.AGENT_CHANGE_PASSWORD, data);
    }

    public changeStatus(data: { [key: string]: string | number }): Observable<any> {
        return this.http.request('put', environment.ApiUrl + Methods.AGENT_CHANGE_STATUS, data);
    }

    public transfer(data: any): Observable<void> {
        return this.http.request('post', environment.ApiUrl + Methods.ACCOUNT_AGENT_TRANSFER, data);
    }

    public earningTransfer(data: any): Observable<void> {
        return this.http.request('post', environment.ApiUrl + Methods.ACCOUNT_EARNING_TRANSFER, data);
    }

    public moneyTransfer(data: any): Observable<void> {
        return this.http.request('post', environment.ApiUrl + Methods.AGENT_MONEY_TRANSFER, data);
    }

    public payout(data: any): Observable<void> {
        return this.http.request('post', environment.ApiUrl + Methods.ACCOUNT_PAYOUT, data);
    }

    public createAgent(agent: any): Observable<any> {
        return this.http.request('post', environment.ApiUrl + Methods.ADD_AGENT, agent);
    }

    public getRoles(id: number): Observable<IRole[]> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GET_PERMISSIONS_BY_AGENT_ID,
            null,
            false,
            { params: { agentId: id } });
    }

    public loadCurrencyEnum(): Observable<string[]> {
        return this.http.request('get', environment.ApiUrl + Methods.GET_CURRENCIES);
    }

    public getPermissionsByAgentId(agentId: number, isAdmin: boolean): Observable<any> {
        const method = isAdmin ? Methods.GET_AGENT_PERMISSIONS : Methods.GET_PERMISSION_BY_AGENT_ID;
        return this.http.request(
            'get',
            environment.ApiUrl + method,
            null,
            false,
            { params: { agentId } });
    }

    public editAgentPermissions(permissionList: IAgentPermissionsInfo): Observable<IAgentPermissionsInfo> {
        return this.http.request('put', environment.ApiUrl + Methods.UPDATE_AGENT_PERMISSION, permissionList);
    }

    public getEarningsById(payload: any): Observable<IResponseWithCount<IEarning[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_EARNINGS_BY_AGENT_ID,
            payload,
            false);
    }

    public getEarningTypes(): void {
        if (localStorage.getItem('enum_earning-types')) {
            this.earningTypes$.next(JSON.parse(localStorage.getItem('enum_earning-types')));
        } else {
            this.http.request('get', environment.ApiUrl + Methods.GET_EARNING_TYPES)
                .pipe(take(1))
                .subscribe((res) => {
                    localStorage.setItem('enum_earning-types', JSON.stringify(res));
                    this.earningTypes$.next(res);
                });
        }
    }

    public getBalanceTransfers(payload: any): Observable<IResponseWithCount<IBalanceTransfer[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_AGENT_TRANSACTION,
            payload,
            false,
            null,
            false);
    }

    public getUserTransactions(payload: any): Observable<IResponseWithCount<IUserTransaction[]>> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_USER_TRANSACTION,
            payload,
            false,
            null,
            false);
    }

    public getAgentByUserName(payload: any): Observable<IParent[]> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.GET_AGENT_BY_USER_NAME,
            payload,
            false,
            null,
            false);
    }

    public addCommentToAgent(agentComment: IAgentComment): Observable<IAgentComment> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.ADD_AGENT_NOTE,
            agentComment
        );
    }

    public updateAgentComment(editedAgentComment: IAgentComment): Observable<IAgentComment> {
        return this.http.request(
            'put',
            environment.ApiUrl + Methods.UPDATE_AGENT_NOTE,
            editedAgentComment
        );
    }

    public moveAgent(payload: IMoveAgent): Observable<void> {
        return this.http.request(
            'post',
            environment.ApiUrl + Methods.MOVE_AGENT,
            payload
        );
    }

    public generateAgentTreeReport(partnerId: number): Observable<void> {
        return this.http.request(
            'get',
            environment.ApiUrl + Methods.GENERATE_AGENT_TREE_REPORT,
            null,
            false,
            { params: { partnerId } });
    }
}
